import Price from '/components/Price';
import ProductOptionButton from '/components/products/show/product-form/ProductOptionButton';
import PropTypes from 'prop-types';
import SizeGuide from '/components/size-guides/SizeGuide';
import Typography from '/components/Typography';
import { checkProductTags } from '/utils/product-tags';
import classNames from '/utils/class-names';
import { findProductTag } from '/utils/product-tags';
import { getFormattedOptionName } from '/utils/product';
import { observer } from 'mobx-react-lite';
import { trackVariantSelect } from '/services/analytics/shinesty-analytics';
import { useEffect } from 'react';

import { readLocalStorage, writeLocalStorage } from '/utils/local-storage';

const getLabel = (product, isJoinedProduct) => {
	const isPajamas = product.productType === 'Pajamas';
	const isTrunks = product.productType === 'Trunks';
	const isWovens = product.productType === "Men's SS Wovens";

	if (!isPajamas && !isTrunks && isWovens && isJoinedProduct) {
		const titleIndex = product.tags.findIndex((tag) => tag.includes('joined type:'));
		if (titleIndex != -1) {
			return product.tags[titleIndex].split(':')[1].trim();
		}
	}

	if (!isPajamas && !isTrunks && !isWovens) {
		return product.productType;
	}

	if (isTrunks && (product.hasJoinedProducts || isJoinedProduct)) {
		return 'Matching Trunks';
	}

	if (isWovens && (product.hasJoinedProducts || isJoinedProduct)) {
		return 'Matching Hawaiian';
	}

	const isPajamaBottoms = checkProductTags(product.tags, 'product type: pajama bottoms');
	if (isPajamaBottoms) {
		return 'Pajama Bottoms';
	}

	const isPajamaTops = checkProductTags(product.tags, 'product type: pajama tops');
	if (isPajamaTops) {
		return 'Pajama Tops';
	}

	return product.productType;
};

const ProductOptions = ({
	activeHandle,
	centerButtons = false,
	hasSingleSizeOption = false,
	isJoinedProduct = false,
	isPackedProduct = false,
	packProducts,
	position,
	product,
	primary = true,
	selectedOptions,
	setSelectedOptions,
	setBackInStockSettings,
	showLabel = true,
	showFromPrice = false,
	showNoneOption = false,
	showProductType = false,
}) => {
	useEffect(() => {
		if (
			hasSingleSizeOption === true &&
			product?.variants?.length &&
			product?.variants[0]?.quantityAvailable > 0
		) {
			handleSelectedOption({
				disabled: false,
				primary,
				product,
				variant: product.variants[0],
			});
		}
	}, [activeHandle]);

	useEffect(async () => {
		if (!product.productType || hasSingleSizeOption) {
			return;
		}

		// const selectedSize = sizeStore.getSize(product.productType);
		const genderKey = product.productType.includes('Boxer')
			? 'sizestoremaleunderwear'
			: 'sizestorefemaleunderwear';

		const selectedSize = readLocalStorage(genderKey);

		if (selectedSize) {
			const variant = product.variants.find((variant) => {
				return variant.title === selectedSize;
			});

			if (variant && variant?.dcInventory?.value > 1 && variant.quantityAvailable > 1) {
				handleSelectedOption({
					disabled: false,
					primary,
					product,
					variant: variant,
				});
			}
		}
	}, [activeHandle]);

	/**
	 * @param {*} productId the selected product id (could be main, joined, etc)
	 * @param {*} variantId the selected variant id
	 * @param {*} primary the object is the primary product on the page
	 * @param {*} diabled adds disabled visal settings to the input (variant still selectable)
	 */
	const handleSelectedOption = (newOption) => {
		let newOptions = [...selectedOptions];

		const formattedOption = {
			disabled: newOption.disabled,
			handle: newOption.product.handle,
			primary: primary,
			product: newOption.product,
			productId: newOption.product.id,
			type: newOption.product.productType,
			variantId: newOption.variant.id,
			variantSku: newOption.variant.sku,
			variantTitle: newOption.variant.title,
		};

		if (formattedOption.primary === true) {
			const genderKey = formattedOption.product.productType.includes('Boxer')
				? 'sizestoremaleunderwear'
				: 'sizestorefemaleunderwear';

			writeLocalStorage(genderKey, formattedOption.variantTitle);
		}
		let samePackProducts = packProducts && packProducts[0].handle == packProducts[1]?.handle;

		let existingIndex;
		if (position && samePackProducts) {
			existingIndex = position;
		} else {
			existingIndex = selectedOptions.findIndex((existingOption) => {
				return existingOption.productId === formattedOption.productId;
			});
		}

		// if the clicked option is the same as the existing option, remove it
		// if the clicked option exists, but the new option is different, change it
		// otherwise, add it

		if (
			existingIndex >= 0 &&
			newOptions[existingIndex] &&
			newOptions[existingIndex].variantId === formattedOption.variantId
		) {
			newOptions.splice(existingIndex, 1);
		} else if (existingIndex >= 0 && newOptions[existingIndex]) {
			newOptions[existingIndex] = formattedOption;
		} else {
			newOptions.push(formattedOption);
		}

		if (setBackInStockSettings) {
			if (newOption.disabled) {
				if (!primary) {
					setBackInStockSettings({
						productId: formattedOption.productId,
						show: true,
						overrideShow: true,
						variantId: formattedOption.variantId,
					});
				} else {
					setBackInStockSettings({
						productId: formattedOption.productId,
						show: true,
						variantId: formattedOption.variantId,
					});
				}
			} else {
				setBackInStockSettings({
					show: false,
				});
			}
		}

		if (primary || isPackedProduct) {
			trackVariantSelect(product.id, formattedOption.variantId);
		}

		// if (trackProductView) {
		// 	trackProductView({ products: [newOption.product], variant: newOption.variant });
		// }

		setSelectedOptions(newOptions);
	};

	/**
	 * @param {*} productId the product id (could be primary, joined, etc)
	 * @param {*} variantId the variant id selected (typically a size)
	 * @returns if the variant for that product is selected
	 */
	const isSelected = (productId, variantId) => {
		let found = false;
		if (position >= 0 && packProducts && packProducts[0].handle === packProducts[1]?.handle) {
			found =
				selectedOptions[position] &&
				selectedOptions[position].productId === productId &&
				selectedOptions[position].variantId === variantId;
		} else {
			found = selectedOptions.find(
				(option) => option.productId === productId && option.variantId === variantId,
			);
		}
		return !!found;
	};

	let labelTitle = getLabel(product, isJoinedProduct);

	let productTitle = (
		<Typography className="inline" compoenent="h1" variant="heading-md">
			{labelTitle}
		</Typography>
	);

	const sizeText =
		product?.title?.includes('Happy Nuts') || product.productType == 'Laundry Detergent Sheets'
			? 'Scent'
			: 'Size';

	let sizeLabel = (
		<Typography component="h4" variant="heading-xs">
			{sizeText}
		</Typography>
	);

	// TODO: figure out why BG international is not calibre
	if (showFromPrice) {
		if (product.fromPrice) {
			productTitle = (
				<Typography className="inline" component="h3" variant="heading-xs">
					{labelTitle} from{' '}
					<Typography className="inline line-through !font-body italic" variant="body">
						<Price price={product.prices[0]} />
					</Typography>{' '}
					<Typography className="text-primary">
						<Price price={product.fromPrice} />
					</Typography>
				</Typography>
			);
		} else {
			productTitle = (
				<Typography className="inline" component="h3" variant="heading-xs">
					{labelTitle} from <Price price={product.fromPrice || product.prices[0]} />{' '}
					{product.compareAtPrices && product.compareAtPrices[0] < product.prices[0] && (
						<Typography className="inline line-through">
							<Price price={product.compareAtPrices[0]} />
						</Typography>
					)}
				</Typography>
			);
		}
	}
	const styleNumber = findProductTag(product.tags, 'style', 1);

	if (hasSingleSizeOption) {
		return null;
	}

	return (
		<div>
			{showLabel && (
				<div className="flex justify-between mb-2">
					{(showProductType && <div>{productTitle}</div>) || sizeLabel}
					<SizeGuide styleNumber={styleNumber} />
				</div>
			)}
			<div className={classNames('flex flex-wrap', centerButtons ? 'justify-center' : '')}>
				{product.variants.map((variant, i) => {
					return (
						<ProductOptionButton
							displayValue={getFormattedOptionName(variant.title)}
							key={`${i}`}
							name={product.title}
							onSelect={(p, v, d) => {
								handleSelectedOption({
									product: p,
									variant: v,
									disabled: d,
									primary: primary,
								});
							}}
							selectedOptions={selectedOptions}
							product={product}
							selected={isSelected(product.id, variant.id)}
							value={variant.id}
							variant={variant}
						/>
					);
				})}
				{showNoneOption && (
					<ProductOptionButton
						displayValue="None"
						name={product.title}
						product={product}
						onSelect={(p, v, d) =>
							handleSelectedOption({
								disabled: d,
								product: p,
								variant: v,
								primary: false,
								quantity: 0,
							})
						}
						selected={isSelected(product.id, 'none')}
						value="none"
						variant={{
							id: 'none',
							sku: 'none',
							title: 'none',
						}}
					/>
				)}
			</div>
		</div>
	);
};

ProductOptions.displayName = 'ProductOptions';

ProductOptions.propTypes = {
	activeHandle: PropTypes.string,
	centerButtons: PropTypes.bool,
	hasSingleSizeOption: PropTypes.bool,
	isCouplesPage: PropTypes.bool,
	isJoinedProduct: PropTypes.bool,
	isPackedProduct: PropTypes.bool,
	packProducts: PropTypes.array,
	position: PropTypes.number,
	presetSize: PropTypes.string,
	primary: PropTypes.bool,
	product: PropTypes.object,
	selectedOptions: PropTypes.array,
	setBackInStockSettings: PropTypes.func,
	setSelectedOptions: PropTypes.func,
	showFromPrice: PropTypes.bool,
	showLabel: PropTypes.bool,
	showNoneOption: PropTypes.bool,
	showProductType: PropTypes.bool,
};

export default observer(ProductOptions);
